import { useEffect } from 'react'

export default function NotFound() {
  const redirectPath = '/'

  useEffect(() => {
    if (redirectPath && typeof window !== 'undefined') {
      window.location.href = redirectPath
    }
  }, [redirectPath])

  return null
}
